import QueueableRequest from './QueueableRequest';
import { CASHBACK_TELLJA } from '../../config/api';
import { REQUEST_METHOD_GET } from '../../helpers/constants';
import { bindParamsToRoute } from '../../helpers/url';

export default class TelljaSessionRequest extends QueueableRequest {
  constructor(advId) {
    super(bindParamsToRoute(CASHBACK_TELLJA, { advId }), {
      method: REQUEST_METHOD_GET,
    });
  }
}
