import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TelljaContext } from './CachbackProvider';
import { QUERY_ORDER_ID } from '../../../helpers/constants';

const TelljaOrderTracking = ({
  tariff,
  contractId,
}) => {
  const {
    deleteReferral,
    referral,
    cashbackActive,
    isAvailableForTariff,
    config,
  } = useContext(TelljaContext);

  const [localReferral, setReferral] = useState(null);

  useEffect(() => {
    // keep in local state to handle rerendering but remove from store to clean up
    if (cashbackActive && referral) {
      setReferral(referral);
      deleteReferral();
    }
  }, []);

  if (!isAvailableForTariff(tariff) || !config || !localReferral || !contractId) {
    return <></>;
  }

  const {
    trackingUrl,
    tariffMap,
  } = config;

  const telljaUrl = trackingUrl.replace('{ORDER_ID}', contractId)
    .replace('{REFERRAL}', localReferral)
    .replace('{CATEGORY}', tariffMap[tariff.iid]);

  return (
    <>
      <img
        src={telljaUrl}
        height="0px"
        width="0px"
        border="0"
        alt=""
        title=""
      />
    </>
  );
};

TelljaOrderTracking.propTypes = {
  tariff: PropTypes.object,
  contractId: PropTypes.string,
};

function mapStateToProps(state) {
  const { order, site } = state;
  const orderId = site?.routing?.currentLocation?.query?.[QUERY_ORDER_ID];
  const response = (order[orderId] && order[orderId].response) || {};

  return {
    contractId: response.contractId,
  };
}

export default connect(mapStateToProps)(TelljaOrderTracking);
