import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getItem,
  removeItem,
  setItem,
  STORAGE_TYPE_LOCAL_STORAGE,
} from '../../../helpers/storage';
import { updateShoppingBag } from '../../../actions/global/storage';
import { HOUR } from '../../../helpers/date';

const TELLJA_REFERRAL_KEY = 'telljaReferral';
const TELLJA_REFERRAL_TTL = 8 * HOUR;

const defaultContext = {
  referral: null,
  setReferral: () => {
  },
  deleteReferral: () => {
  },
  cashbackActive: false,
  advId: null,
  isAvailableForTariff: () => {
  },
  config: {},
};

const TelljaContext = createContext(defaultContext);

const isValidConfig = (config) => (
  config && config.advId && config.targetTariffs
);

const Provider = ({ children, config, setReferralInShoppingBag, isContractRenewal }) => {
  if (!isValidConfig(config)) {
    return <TelljaContext.Provider value={defaultContext}>{children}</TelljaContext.Provider>;
  }

  const {
    advId,
    targetTariffs,
  } = config;

  const isAvailableForTariff = (tariff) => (
    targetTariffs.includes(tariff.iid)
  );

  const [referral, _setReferral] = useState(null);
  const cashbackActive = referral !== null;

  const setReferral = (ref) => {
    console.log('set', ref);
    _setReferral(ref);
    setItem(STORAGE_TYPE_LOCAL_STORAGE, TELLJA_REFERRAL_KEY, { ref }, {
      expires: new Date(Date.now() + TELLJA_REFERRAL_TTL),
    });
    // add it to shopping cart to be available for tracking
    setReferralInShoppingBag(ref);
  };

  const deleteReferral = () => {
    _setReferral(null);
    removeItem(STORAGE_TYPE_LOCAL_STORAGE, TELLJA_REFERRAL_KEY);
    setReferralInShoppingBag(null);
  };

  useEffect(() => {
    const { ref } = getItem(STORAGE_TYPE_LOCAL_STORAGE, TELLJA_REFERRAL_KEY) || {};
    if (ref) {
      setReferral(ref);
    } else {
      deleteReferral();
    }
  }, []);

  useEffect(() => {
    if (isContractRenewal) {
      deleteReferral();
    }
  }, [isContractRenewal]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const listener = () => {
        const { ref } = getItem(STORAGE_TYPE_LOCAL_STORAGE, TELLJA_REFERRAL_KEY) || {};
        if (ref) {
          setReferral(ref);
        } else {
          deleteReferral();
        }
      };
      // eslint-disable-next-line no-undef
      window.addEventListener('storage', listener);
      return () => {
        // eslint-disable-next-line no-undef
        window.removeEventListener('storage', listener);
      };
    }
    return () => {
    };
  }, []);

  return (
    <TelljaContext.Provider value={{
      referral,
      setReferral,
      deleteReferral,
      cashbackActive,
      advId,
      isAvailableForTariff,
      config,
    }}
    >
      {children}
    </TelljaContext.Provider>
  );
};

TelljaContext.propTypes = {
  referral: PropTypes.string,
  setReferral: PropTypes.func,
  deleteReferral: PropTypes.func,
  cashbackActive: PropTypes.bool,
  advId: PropTypes.string,
  isAvailableForTariff: PropTypes.func,
  config: PropTypes.object,
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.shape({
    advId: PropTypes.string,
    targetTariffs: PropTypes.arrayOf(PropTypes.string),
  }),
  setReferralInShoppingBag: PropTypes.func,
  isContractRenewal: PropTypes.bool,
};

const mapStateToProps = state => ({
  isContractRenewal: state.site?.contractRenewal?.isInProgress || false,
});

const mapDispatchToProps = dispatch => ({
  setReferralInShoppingBag: (referral) => {
    dispatch(updateShoppingBag({ cashbackReferral: referral }));
  },
});

const TelljaProvider = connect(mapStateToProps, mapDispatchToProps)(Provider);

export { TelljaContext, TelljaProvider };
