/* global document */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import suitcss from '../../../helpers/suitcss';
import {
  ENTITY_TYPE_HARDWARE,
  MARKETING_LOCATION_CHECKOUT_CART,
  PAYMENT_FEE_MONTHLY,
  PAYMENT_FEE_SINGLE,
  MARKET_POSTPAID,
  MARKET_PREPAID,
  ENTITY_TYPE_TARIFF,
  DIALOG_ID_SHOPPING_CART_ESIM_HINT,
  E_SIM,
  SIM_CARD,
} from '../../../helpers/constants';
import {
  getCreditIncentives,
  getPaymentFeeReduction,
  getPaymentFeeReductions,
  getPromotionIncludedProducts,
  hasPromotionsIncludesProduct,
} from '../../../helpers/promotions';
import {
  getBundlePaymentFee,
  getBundleSinglePaymentFeeIncludingShipping,
  getPaymentFeeDuration,
} from '../../../helpers/entity';

import { shape as tariffShape } from '../../../propTypes/tariff';
import { list as optionShapeList } from '../../../propTypes/tariffOption';
import { shape as hardwareShape } from '../../../propTypes/hardware';
import { shape as hardwareGroupShape } from '../../../propTypes/hardwareGroup';

import matchMediaConnector from '../../../containers/service/ServiceMatchMedia';
import connectUI from '../../basics/ui/UIConnector';
import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';

import ShoppingCartProduct from './ShoppingCartProduct';
import ShoppingCartOption from './ShoppingCartOption';
import ShoppingCartRow from './ShoppingCartRow';
import PromotionCodeForm from '../../../containers/promotion/PromotionCodeForm';
import PromotionCodeFormLink from '../promotion/PromotionCodeFormLink';

import FormOptionBox from '../../basics/form/FormOptionBox';
import { showTemplateDialog, showDialog } from '../../../actions/page/dialog';
import Link from '../../basics/text/TextLink';
import FieldRadioGroup from '../../basics/form/FieldRadioGroup';
import { showSimArticleListDialog } from '../../../actions/dialog/misc';
import HardwareDeliveryState from '../hardware/HardwareDeliveryState';
import TelljaShoppingCart from '../cashback/TelljaShoppingCart';

class ShoppingCart extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      toggled: props.isInitiallyOpen,
      isEsim: this.props.cartSimType === E_SIM,
    };
    this.onToggle = this.onToggle.bind(this);
    this.handleClickOptionBullet = this.handleClickOptionBullet.bind(this);
    this.handleHardwareRemoval = this.handleHardwareRemoval.bind(this);
    this.getSimTypeHint = this.getSimTypeHint.bind(this);

    /*
    Old Mnp Hint solution should be removed because of OP-3090 Web | Rework MNP-Hint
  *   We keep it in case we need it again.

    this.onToggleMnpInfo = this.onToggleMnpInfo.bind(this);
    */
  }

  componentDidMount() {
    const { showSimArticleList } = this.props;
    const deviceCheckLink = document.querySelector('.deviceCheckLink');
    if (deviceCheckLink) {
      deviceCheckLink.addEventListener('click', showSimArticleList);
    }
  }

  componentDidUpdate(prevProps) {
    const { cartSimType } = this.props;
    if (prevProps.cartSimType !== cartSimType) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isEsim: this.props.cartSimType === E_SIM,
      });
    }
  }

  componentWillUnmount() {
    const { showSimArticleList } = this.props;
    const deviceCheckLink = document.querySelector('.deviceCheckLink');
    if (deviceCheckLink) {
      deviceCheckLink.removeEventListener('click', showSimArticleList);
    }
  }

  onToggle(ev) {
    ev.preventDefault();
    this.setState({ toggled: !this.state.toggled });
  }

  /*
    Old Mnp Hint solution should be removed because of OP-3090 Web | Rework MNP-Hint
    *   We keep it in case we need it again.

    onToggleMnpInfo() {
      this.props.dispatch(trackMnp(!this.props.mnpActivated));
    }
  */

  onOptionsChange(evt) {
    const { value: selectedValue } = evt.target;
    const { selectedOptions, onOptionsChange: onChange } = this.props;
    const alreadySelected = selectedOptions.includes(selectedValue);

    const nextSelectedOptions = [...selectedOptions];
    if (alreadySelected) {
      nextSelectedOptions.splice(nextSelectedOptions.indexOf(selectedValue), 1);
    } else {
      nextSelectedOptions.push(selectedValue);
    }

    onChange(nextSelectedOptions);
  }

  onOptionalPromoChange(promo) {
    const {
      selectedOptionalPromotionIds,
      onOptionsChange,
      onOptionalPromoChange: onChange,
      selectedOptions,
    } = this.props;
    const includedProducts = getPromotionIncludedProducts(promo);
    const alreadySelected = selectedOptionalPromotionIds.includes(promo.eid);
    const nextSelectedOptionalPromos = selectedOptionalPromotionIds;
    const nextSelectedOptions = [...selectedOptions];

    if (alreadySelected) {
      nextSelectedOptionalPromos.splice(nextSelectedOptionalPromos.indexOf(promo.eid), 1);
    } else {
      nextSelectedOptionalPromos.push(promo.eid);
      // remove tariff option from shopping cart if is included in promotion
      onOptionsChange(nextSelectedOptions.filter(option => !includedProducts.includes(option)));
    }

    onChange(nextSelectedOptionalPromos);
  }

  getSimTypeHint(isEsim, supportsEsim, supportsSimCard, ui) {
    if ((typeof supportsEsim === 'undefined' || supportsEsim === null)
      && (typeof supportsSimCard === 'undefined' || supportsSimCard === null)) {
      return '';
    }
    if ((typeof supportsEsim === 'undefined' || supportsEsim === null)) {
      if (supportsSimCard) {
        // in every case, there is no hint needed
        return '';
      } else {
        // e-sim => physical wouldn't be supported, e-sim implicit supported
        // physical => not supported, e-sim implicit supported
        return isEsim ? ui.scaHardwareSupportsEsimHint : ui.scaHardwareNoSimCardClassicSupportHint;
      }
    }
    if ((typeof supportsSimCard === 'undefined' || supportsSimCard === null)) {
      if (supportsEsim) {
        // e-sim => supported, physical no info
        // physical => e-sim would be supported, physical no info => suggestion needed
        return isEsim ? ui.scaHardwareSupportsEsimHint : ui.scaHardwareEsimSuggestionHint;
      } else {
        // e-sim => not supported, physical implicit supported
        // physical => e-sim not supported, physical implicit supported
        return isEsim ? ui.scaHardwareNoEsimSupportHint : '';
      }
    }
    if (supportsEsim) {
      return isEsim ? ui.scaHardwareSupportsEsimHint
        : (supportsSimCard ? ui.scaHardwareEsimSuggestionHint
          : ui.scaHardwareNoSimCardClassicSupportHint);
    } else {
      return isEsim ? ui.scaHardwareNoEsimSupportHint : '';
    }
  }

  handleClickOptionBullet() {
    const { onSimTypeOptionChange } = this.props;
    this.setState(
      (prevState) => ({ isEsim: !prevState.isEsim }),
      () => {
        const selectedSimType = this.state.isEsim ? E_SIM : SIM_CARD;
        onSimTypeOptionChange(selectedSimType);
      },
    );
  }

  handleHardwareRemoval(eid) {
    const { onHardwareRemove, onSimTypeOptionChange } = this.props;

    onHardwareRemove(eid);
    this.setState({ isEsim: false }, () => {
      onSimTypeOptionChange(SIM_CARD);
    });
  }

  isCompactTheme() {
    return this.props.theme === 'compact';
  }

  isWidgetTheme() {
    return this.props.theme === 'widget';
  }

  isFullTheme() {
    return this.props.theme === 'full';
  }

  renderHeader() {
    const { selectedTariff, ui, isMobileCheckout } = this.props;
    const { toggled } = this.state;
    const uiPayment = getPaymentFeeDuration(selectedTariff, ui);
    const uiPaymentAbbr = getPaymentFeeDuration(selectedTariff, ui, true);
    const isCompact = this.isCompactTheme();
    const isWidget = this.isWidgetTheme();
    return (
      <div className={suitcss({ descendantName: 'header', utilities: ['row', isCompact && 'itemsEnd', 'sJustifyEnd'] }, this)}>
        {!isCompact && !isWidget && [
          <Headline size="xxs" utilities={['sCol6', 'mlCol8', 'weightBold']} embedded key="0">
            {ui.cfoYourSelection}
          </Headline>,
          <Headline size="xxs" utilities={['sCol3', 'mlCol2', 'sHidden', 'alignRight', 'weightBold']} embedded key="1">
            {ui.guiWordSingular}
          </Headline>,
          <Headline size="xxs" utilities={['sCol3', 'mlCol2', 'mlHidden', 'alignRight', 'weightBold']} embedded key="2">
            {ui.guiWordSingularAbbr}
          </Headline>,
          <Headline size="xxs" utilities={['sCol3', 'mlCol2', 'sHidden', 'alignRight', 'weightBold']} embedded key="3">
            {uiPayment}
          </Headline>,
          <Headline size="xxs" utilities={['sCol3', 'mlCol2', 'mlHidden', 'alignRight', 'weightBold']} embedded key="4">
            {uiPaymentAbbr}
          </Headline>,
        ]}
        {(isCompact || isWidget) && [
          <Headline
            size="xs"
            bold
            utilities={[
              isCompact && 'col6',
              isWidget && 'sCol6',
              isWidget && 'mlCol8',
              isMobileCheckout && !toggled && 'sHidden',
            ]}
            embedded
            key="0"
          >
            {ui.cfoYourSelection}
          </Headline>,
          <Headline
            size="xxs"
            utilities={[
              isCompact && 'col3',
              isWidget && 'sCol3',
              isWidget && 'mlCol2',
              'colorGray100',
              'alignRight',
            ]}
            embedded
            key="1"
          >
            {isCompact ? ui.guiWordSingular : ui.guiWordSingularAbbr}
          </Headline>,
          <Headline
            size="xxs"
            utilities={[
              isCompact && 'col3',
              isWidget && 'sCol3',
              isWidget && 'mlCol2',
              'colorGray100',
              'alignRight',
            ]}
            embedded
            key="2"
          >
            {isCompact ? uiPayment : uiPaymentAbbr}
          </Headline>,
        ]}
      </div>
    );
  }

  renderProduct(type, isAdjacentBottom) {
    const {
      theme,
      selectedTariff,
      selectedHardware,
      selectedHardwareGroup,
      onTariffRemove,
      isConfirmed,
      isContractRenewal,
      ui,
      cartSimType,
    } = this.props;

    const entities = [selectedTariff, selectedHardware, selectedHardwareGroup];
    const entity = entities.find(e => e.etype === type);
    const {
      paymentFee,
      paymentFeeStrike,
      singlePaymentFee,
      singlePaymentFeeStrike,
    } = entity;

    const isCompact = this.isCompactTheme();
    const isWidget = this.isWidgetTheme();
    const isFull = this.isFullTheme();
    const isHardware = type === ENTITY_TYPE_HARDWARE;
    const isTariff = type === ENTITY_TYPE_TARIFF;
    const isPua = selectedTariff.market === MARKET_PREPAID;
    const isPuc = selectedTariff.market === MARKET_POSTPAID;
    const onRemove = !isContractRenewal && isHardware ? this.handleHardwareRemoval : null;
    const onRemoveAll = !isContractRenewal && isTariff ? onTariffRemove : null;

    return (
      <ShoppingCartRow
        theme={theme}
        className={suitcss({ descendantName: 'product' }, this)}
        singlePaymentFee={singlePaymentFee}
        singlePaymentFeeStrike={singlePaymentFeeStrike}
        paymentFee={paymentFee}
        paymentFeeStrike={paymentFeeStrike}
        isAdjacentBottom={isAdjacentBottom && isFull}
        isPua={isPua}
        showGratis={isCompact}
        wordGratis={ui.guiWordGratis}
      >
        <ShoppingCartProduct
          theme={theme}
          type={type}
          entities={entities}
          onRemove={onRemove}
          onRemoveAll={onRemoveAll}
          isConfirmed={isConfirmed}
          isContractRenewal={isContractRenewal}
          withCallout={!isWidget}
          cartSimType={cartSimType}
          isPuc={isPuc}
        />
      </ShoppingCartRow>
    );
  }

  renderSimTypeOptionToggle() {
    const {
 ui, dispatch, selectedHardware, cartSimType,
} = this.props;
    const isCartEsimType = cartSimType === E_SIM;
    const simTypeHint = selectedHardware && this.getSimTypeHint(
      isCartEsimType,
      selectedHardware.supportsEsim,
      selectedHardware.supportsSimCard,
      ui,
    );
    const incompatibleSimTypeHint = [
      ui.scaHardwareNoEsimSupportHint,
      ui.scaHardwareEsimSuggestionHint,
      ui.scaHardwareNoSimCardClassicSupportHint,
    ].includes(simTypeHint);

    const dialogCopySim = (
      <div className={suitcss({ utilities: ['row'] }, this)}>
        <img
          className={suitcss({ utilities: ['col5', 'marginRight'] })}
          src={ui.scaTripleSimImage}
          alt="triple-sim"
        />
        <div className={suitcss({ descendantName: 'copy', utilities: ['col6'] }, this)}>
          <Copy utilities={['weightBold']} embedded raw>
            {ui.mySimrepSimtypeTrippleSim}
          </Copy>
          <Copy size="secondary" embedded raw>{ui.txtMySimrepSimtypeHint}</Copy>
        </div>
      </div>
    );

    const createDialogLabelInfo = (isSimCard) => () => {
      dispatch(showDialog({
        headline: isSimCard
          ? ui.mySimrepChooseSimtypeOptionClassic
          : ui.mySimrepChooseSimtypeOptionEsim,
        copy: isSimCard
          ? dialogCopySim
          // eslint-disable-next-line
          : (ui.txtMySimrepEsimProsCopy + '<br/>' + ui.mySimrepEsimMoreInfo),
        actions: [{ label: ui.guiWordAllright }],
      }));
    };

    const createCustomLabel = (isSimCardLabel) => (
      <div className={suitcss({ descendantName: 'customLabel' }, this)}>
        <Copy utilities={['weightBold', 'inline', 'marginRightXS']}>
          {isSimCardLabel
            ? ui.scaChooseSimtypeOptionClassic
            : ui.scaChooseSimtypeOptionEsim}
        </Copy>
        <Link
          element="button"
          withoutArrow
          withoutStyle
          onClick={isSimCardLabel ? createDialogLabelInfo(true) : createDialogLabelInfo(false)}
          utilities={['alignMiddle']}
        >
          <img className={suitcss({ utilities: ['alignBaseline'] }, this)} src="/files/icons/info-i/info-icon.svg" alt="info-i" width="16px" height="16px" />
        </Link>
        {simTypeHint
          && ((isSimCardLabel && !isCartEsimType) || (!isSimCardLabel && isCartEsimType)) && (
          <Copy
            className={suitcss({
              descendantName: 'customLabelHint',
              modifiers: [incompatibleSimTypeHint && 'orange'],
              utilities: ['marginBottomAuto'],
            }, this)}
          >
            {simTypeHint}
          </Copy>
        )}
      </div>
    );

    const input = {
      onChange: this.handleClickOptionBullet,
      value: true,
    };

    const options = [
      {
        name: 'sim',
        label: '',
        customLabelEnhancement: createCustomLabel(true),
        value: !this.state.isEsim,
      },
      {
        name: 'esim',
        label: '',
        customLabelEnhancement: createCustomLabel(false),
        value: this.state.isEsim,
      },
    ];

    const deviceCheckLink = `<span class="deviceCheckLink u-colorPrimary u-weightBold">${
      ui.scaDeviceCheckLink
    }</span>`;
    const infoCopy = ui.scaDeviceCheckHint
      .replace('{DEVICE_CHECK}', deviceCheckLink);

    return (
      <div
        className={suitcss({ descendantName: 'simTypeOption', utilities: ['padding'] }, this)}
        // theme={theme}
        // withoutPrices
      >
        <Headline size="xs" utilities={['weightBold', 'paddingVXS']} raw>
          {ui.scaSimtypeOptionHeadline}
        </Headline>
        <FieldRadioGroup utilities={['marginLeftXS']} options={options} input={input} meta={{}} asStack />
        <Copy utilities={['marginTop']} raw>{infoCopy}</Copy>
      </div>
    );
  }

  renderOption(option, key, list) {
    const {
      theme,
      selectedOptions,
      promotions,
      isConfirmed,
      ui,
    } = this.props;
    // check if option is included in promotions
    const isOptionIncludedInPromotions = hasPromotionsIncludesProduct(promotions, option.eid);
    // select if option is included in promotions
    const isSelected = selectedOptions.includes(option.eid) || isOptionIncludedInPromotions;
    // set payment to gratis if option is included in promotion
    const paymentFee = isOptionIncludedInPromotions ? { unit: 0, currency: 'EUR' } : option.paymentFee;
    // set gratis payment if option is included in promotion
    const isMarketingOption = option.marketingLocation.includes(MARKETING_LOCATION_CHECKOUT_CART);
    const isCompact = this.isCompactTheme();
    const isFull = this.isFullTheme();
    return (
      <ShoppingCartRow
        theme={theme}
        className={suitcss({ descendantName: 'option' }, this)}
        paymentFee={paymentFee}
        paymentFeeStrike={!isOptionIncludedInPromotions && option.paymentFeeStrike}
        isDisabled={isSelected && isOptionIncludedInPromotions}
        isHighlighted={!isSelected && isMarketingOption}
        isAdjacentTop={isFull}
        isAdjacentBottom={key < list.length - 1 && isFull}
        key={key}
        showGratis={isOptionIncludedInPromotions || isCompact}
        wordGratis={ui.guiWordGratis}
      >
        <ShoppingCartOption
          theme={theme}
          item={option}
          isConfirmed={isConfirmed}
          selected={isSelected}
          disabled={isOptionIncludedInPromotions}
          onChange={evt => this.onOptionsChange(evt)}
        />
      </ShoppingCartRow>
    );
  }

  renderOptionalPromotion(promo) {
    const {
      theme,
      selectedOptionalPromotionIds,
    } = this.props;
    // check if this promotion is included in selected optional promotions and set as selected
    const isSelected = selectedOptionalPromotionIds.includes(promo.eid);
    const headline = promo.cartBehavior === 'section' && promo.shoppingCartTitle && (
      <ShoppingCartRow
        theme={theme}
        key={`headline-${promo.id}`}
        className={suitcss({ descendantName: 'optionalPromotionsHeadline' }, this)}
        isAdjacentBottom
        withoutPrices
      >
        <Headline size="xs" utilities={['weightBold', 'paddingVXS']} raw>
          {promo.shoppingCartTitle}
        </Headline>
      </ShoppingCartRow>
    );

    const customTrackingMap = {
        ID1052: 'otelokombi internet discount',
    };
    const incentiveId = promo?.incentives?.[0]?.id.split('-')?.[0];
    const customTrackingTitle = customTrackingMap[incentiveId];

    /* render checkbox with optional promo label */
    const optionBox = (
      <ShoppingCartRow
        theme={theme}
        className={suitcss({ descendantName: 'promotionOption' }, this)}
        key={promo.id}
        isAdjacentTop={!!promo.shoppingCartTitle}
        isMarked
        withoutPrices
      >
        <FormOptionBox
          id={promo.eid}
          label={promo.customerActionLabel}
          value={promo.eid}
          checked={isSelected}
          onChange={() => this.onOptionalPromoChange(promo)}
          data={{
            'tracking-title': customTrackingTitle,
          }}
        />
      </ShoppingCartRow>
    );
    return [
      headline,
      optionBox,
    ];
  }

  renderPromotions() {
    const {
      theme,
      selectedTariff,
      promoIncentives,
      selectedOptionalPromoIncentives,
      ui,
      isConfirmed,
    } = this.props;
    const isCompact = this.isCompactTheme();
    const isWidget = this.isWidgetTheme();
    const isFull = this.isFullTheme();
    const incentiveEntries = (isFull && !isConfirmed)
      ? promoIncentives
      : [...promoIncentives, ...selectedOptionalPromoIncentives];
    const entries = incentiveEntries.map((incentive) => (
      incentive.type === 'credit'
        ? {
          key: incentive.id,
          headline: incentive.headline,
          paymentFee: getPaymentFeeReduction(selectedTariff, incentive, PAYMENT_FEE_MONTHLY),
          singlePaymentFee: getPaymentFeeReduction(selectedTariff, incentive, PAYMENT_FEE_SINGLE),
        }
        : { key: incentive.id, headline: incentive.headline }
    ));

    if (entries.length === 0) {
      return null;
    }

    const headline = (
      <ShoppingCartRow
        theme={theme}
        className={suitcss({ descendantName: 'promotionsHeadline' }, this)}
        isAdjacentBottom={isFull}
        key="headline"
        showGratis={isCompact}
        wordGratis={ui.guiWordGratis}
      >
        <Headline size="xs" utilities={['colorPrimary', 'weightBold']} raw>
          {ui.guiPromoPrefix}
        </Headline>
      </ShoppingCartRow>
    );

    return [
      !isCompact && !isWidget && headline,
      ...incentiveEntries.map(this.renderPromotion, this),
    ];
  }

  renderPromotion(incentive, index, list) {
    const { theme, ui } = this.props;
    const {
      id,
      copy,
      paymentFee,
      singlePaymentFee,
      hasHeader,
    } = incentive;
    const isWidget = this.isWidgetTheme();
    const isCompact = this.isCompactTheme() || isWidget;
    const isLast = index === list.length - 1;
    return (
      <ShoppingCartRow
        theme={theme}
        className={suitcss({ descendantName: 'promotion' }, this)}
        paymentFee={paymentFee}
        singlePaymentFee={singlePaymentFee}
        isAdjacentTop={!isCompact || hasHeader}
        isAdjacentBottom={!isLast && !isCompact}
        key={id}
        showGratis={isCompact}
        wordGratis={ui.guiWordGratis}
      >
        <Copy
          utilities={[
            isCompact && 'fontCondensed',
            isCompact && 'textSizeSecondary',
          ]}
          embedded={isLast || isCompact}
          raw
        >
          {copy}
        </Copy>
      </ShoppingCartRow>
    );
  }

  renderPromoForm() {
    const { theme } = this.props;
    return (
      <ShoppingCartRow
        className={suitcss({ descendantName: 'promoForm' }, this)}
        theme={theme}
        withoutPrices
      >
        <PromotionCodeForm />
      </ShoppingCartRow>
    );
  }

  renderPromoFormLink() {
    const { theme, onShowPromoCodeDialog } = this.props;
    return (
      <ShoppingCartRow
        className={suitcss({ descendantName: 'promoForm' }, this)}
        theme={theme}
        withoutPrices
      >
        <PromotionCodeFormLink isCompact onClick={onShowPromoCodeDialog} />
      </ShoppingCartRow>
    );
  }

  renderMnp() {
    const { theme, ui } = this.props;
    return (
      <ShoppingCartRow
        className={suitcss({ descendantName: 'mnp' }, this)}
        theme={theme}
        withoutPrices
      >
        <div dangerouslySetInnerHTML={{ __html: ui.txtCartMnpHint }} />
      </ShoppingCartRow>
    );
  }

  renderCashback(selectedTariff) {
    const { theme } = this.props;
    return (
      <TelljaShoppingCart
        ui={this.props.ui}
        theme={theme}
        tariff={selectedTariff}
        isCheckoutSummary={this.props.isCheckoutSummary}
      />
    );
  }

  renderEsimHintVvl() {
    const { theme, ui } = this.props;
    return (
      <ShoppingCartRow
        className={suitcss({ descendantName: 'esimHint' }, this)}
        theme={theme}
        withoutPrices
      >
        <Headline size="xxs" utilities={['weightBold']}>
          {ui.myVvlEsimHintHeadline}
        </Headline>
        <Copy>{ui.myVvlEsimHintCopy}</Copy>
      </ShoppingCartRow>
    );
  }

  renderEsimHintPUA() {
    const { theme, ui, dispatch } = this.props;

    return (
      <ShoppingCartRow
        className={suitcss({ descendantName: 'esimHint-pua' }, this)}
        theme={theme}
        withoutPrices
      >
        <Headline size="xxs" utilities={['weightBold']}>
          {ui.scaEsimHintHeadline}
        </Headline>
        <Copy utilities={['inline']}>
          {ui.scaEsimHintCopyPua}
        </Copy>
        <Link
          className={suitcss(
            { descendantName: 'infoIcon' },
            this,
          )}
          element="button"
          onClick={() => dispatch(showTemplateDialog(DIALOG_ID_SHOPPING_CART_ESIM_HINT))}
          withoutArrow
          withoutStyle
          icon="/icons/content-info.svg"
          size="small"
        />
      </ShoppingCartRow>
    );
  }

  renderShipping() {
    const {
 theme, selectedTariff, ui, shippingFee,
} = this.props;
    const isCompact = this.isCompactTheme();
    return (
      <ShoppingCartRow
        theme={theme}
        className={suitcss({ descendantName: 'shipping' }, this)}
        singlePaymentFee={{ unit: shippingFee.unit, currency: selectedTariff.paymentFee.currency }}
        isAdjacentBottom={!isCompact}
      >
        <Copy
          utilities={[
            isCompact && 'fontCondensed',
            'weightBold',
          ]}
          embedded={isCompact}
          raw
        >
          {ui.guiShippingCosts}
        </Copy>

      </ShoppingCartRow>
    );
  }

  renderTotal() {
    const {
      theme,
      selectedTariff,
      selectedHardware,
      optionsList,
      selectedOptions,
      ui,
      promotions,
      isMobileCheckout,
      shippingFee,
    } = this.props;
    const { toggled } = this.state;
    const isCompact = this.isCompactTheme();
    const isFull = this.isFullTheme();
    const creditIncentives = getCreditIncentives(promotions);
    const options = optionsList.filter(option => selectedOptions.includes(option.eid));
    const totalPrices = {
      paymentFee: getBundlePaymentFee(
        { hardware: selectedHardware, tariff: selectedTariff },
        ...options,
        ...getPaymentFeeReductions(selectedTariff, creditIncentives, PAYMENT_FEE_MONTHLY),
      ),
      singlePaymentFee: getBundleSinglePaymentFeeIncludingShipping(
        { hardware: selectedHardware, tariff: selectedTariff, shippingFee },
        ...getPaymentFeeReductions(selectedTariff, creditIncentives, PAYMENT_FEE_SINGLE),
      ),
    };

    totalPrices.paymentFee.prefix = isCompact
      ? getPaymentFeeDuration(selectedTariff, ui)
      : getPaymentFeeDuration(selectedTariff, ui, true);
    totalPrices.singlePaymentFee.prefix = isCompact
      ? ui.guiWordSingular
      : ui.guiWordSingularAbbr;

    return (
      <ShoppingCartRow
        theme={theme}
        className={suitcss({
          descendantName: 'total',
          utilities: [
            isMobileCheckout && !toggled && 'sPaddingTopXXS',
          ],
        }, this)}
        singlePaymentFee={totalPrices.singlePaymentFee}
        paymentFee={totalPrices.paymentFee}
        isAdjacentTop={isFull}
        showGratis={false}
        wordGratis={ui.guiWordGratis}
      >
        <Headline size={isCompact ? 'xs' : 's'} utilities={[isCompact && 'lowercase', 'weightBold']} embedded>
          {ui.guiSumTotal}
        </Headline>
        {!isCompact
          && (
          <Copy size="tertiary" utilities={['textSizeSecondary']} embedded>
            {ui.guiVatIncluded}
          </Copy>
          )}
      </ShoppingCartRow>
    );
  }

  renderFooter() {
    const {
      ui,
      selectedHardware,
      selectedTariff,
      isMobileCheckout,
      promoIncentives,
    } = this.props;
    const { toggled } = this.state;
    const isWidget = this.isWidgetTheme();
    const isCompact = this.isCompactTheme();
    const campusIncentives = [
      'ID1078-1_basic_fee_credit_incentive',
      'ID1078-2_basic_fee_credit_incentive',
      'ID1078-3_basic_fee_credit_incentive',
    ];

    const hasCampusPromo = !!(promoIncentives.filter(
      incentive => campusIncentives.includes(incentive.id),
    ).length);

    const isPua = selectedTariff.market === MARKET_PREPAID;
    const isPuc = selectedTariff.market === MARKET_POSTPAID;
    const marginTop = isWidget ? 'marginTopXS' : 'marginTopS';

    let deliveryHint;
    if (selectedHardware) {
      deliveryHint = <HardwareDeliveryState selectedHardware={selectedHardware} />;
    } else if (isPuc) {
      deliveryHint = ui.guiPucDeliveryTimeHint;
    } else if (isPua) {
      deliveryHint = ui.guiPuaDeliveryTimeHint;
    }

    return (
      <div className={suitcss({ descendantName: 'footer', utilities: ['row', isMobileCheckout && !toggled && 'sHidden'] }, this)}>
        {!isWidget && (
        <Copy utilities={[!isCompact && 'sCol12', !isCompact && 'mlCol8', isCompact && 'colorGray100', isCompact && 'textSizeSecondary', isCompact && 'marginTopS']} embedded>
          {deliveryHint}
        </Copy>
)}
        { hasCampusPromo
        && (
        <Copy
          utilities={[!isCompact && 'sCol12', !isCompact && 'mlCol8', isCompact && 'colorGray100', 'textSizeSecondary', marginTop]}
          embedded
        >
          {ui.sca1078Exception}
        </Copy>
)}
      </div>
    );
  }

  render() {
    const {
      theme,
      selectedTariff,
      selectedHardware,
      selectedOptions,
      optionsList,
      isConfirmed,
      withPromoCodeForm,
      isMediaS,
      isContractRenewal,
      ui,
      hideMnp,
      isMobileCheckout,
      optionalPromotions,
    } = this.props;
    const { toggled } = this.state;
    const isCompact = this.isCompactTheme();
    const isWidget = this.isWidgetTheme();
    const options = isConfirmed
      ? optionsList.filter(option => selectedOptions.includes(option.eid))
      : optionsList;
    const isPua = selectedTariff && selectedTariff.market === MARKET_PREPAID;
    const isPuc = selectedTariff && selectedTariff.market === MARKET_POSTPAID;

    return (
      <section
        className={suitcss({
          modifiers: [
            theme,
            isMediaS && isCompact && 'accordion',
          ],
          states: [
            isConfirmed && 'confirmed',
            isMediaS && isCompact && toggled && 'open',
          ],
        }, this)}
      >
        {isCompact && isMediaS
          && <button className={suitcss({ descendantName: 'toggle' }, this)} onClick={this.onToggle} />}
        {!selectedTariff && (
          <div className={suitcss({ descendantName: 'inner' }, this)}>
            {ui.guiEmptyCart}
          </div>
        )}
        {selectedTariff && (
          <div className={suitcss({ descendantName: 'inner', utilities: [isMobileCheckout && !toggled && 'sPaddingTopS'] }, this)}>
            {this.renderHeader()}
            {selectedHardware && this.renderProduct(selectedHardware.etype)}
            {this.renderProduct(selectedTariff.etype, !!options.length)}
            {!isConfirmed && isPuc && !isContractRenewal && theme === 'full'
              && this.renderSimTypeOptionToggle()}
            {options.map(this.renderOption, this)}
            {!isCompact && !isWidget && !isConfirmed && !!optionalPromotions.length
              && optionalPromotions.map(this.renderOptionalPromotion, this)}
            {this.renderPromotions()}
            {!isContractRenewal && this.renderCashback(selectedTariff)}
            {!isConfirmed && !isContractRenewal && !hideMnp && theme === 'full' && this.renderMnp()}
            {isContractRenewal && theme === 'full' && this.renderEsimHintVvl()}
            {!isConfirmed && isPua && theme === 'full' && this.renderEsimHintPUA()}
            {!isConfirmed && withPromoCodeForm && theme === 'full' && this.renderPromoForm()}
            {this.renderShipping()}
            {this.renderTotal()}
            {this.renderFooter()}
          </div>
        )}
        {isCompact && isMediaS
          && <div className={suitcss({ descendantName: 'icon' }, this)} />}
      </section>
    );
  }
}

ShoppingCart.propTypes = {
  theme: PropTypes.oneOf(['full', 'compact', 'widget']),
  selectedHardwareGroup: hardwareGroupShape,
  selectedHardware: hardwareShape,
  selectedTariff: tariffShape,
  selectedOptions: PropTypes.array.isRequired,
  optionsList: optionShapeList.isRequired,
  promotions: PropTypes.array,
  promoIncentives: PropTypes.array,
  optionalPromotions: PropTypes.array,
  selectedOptionalPromotions: PropTypes.array,
  selectedOptionalPromotionIds: PropTypes.array,
  selectedOptionalPromoIncentives: PropTypes.array,
  onOptionsChange: PropTypes.func,
  onOptionalPromoChange: PropTypes.func,
  onHardwareRemove: PropTypes.func,
  onTariffRemove: PropTypes.func,
  onShowPromoCodeDialog: PropTypes.func,
  isConfirmed: PropTypes.bool,
  isContractRenewal: PropTypes.bool,
  isCheckoutSummary: PropTypes.bool,
  isMediaS: PropTypes.bool,
  withPromoCodeForm: PropTypes.bool,
  hideMnp: PropTypes.bool,
  dispatch: PropTypes.func,
  isInitiallyOpen: PropTypes.bool,
  isMobileCheckout: PropTypes.bool,
  shippingFee: PropTypes.object,
  showSimArticleList: PropTypes.func,
  onSimTypeOptionChange: PropTypes.func,
  cartSimType: PropTypes.string,
  ui: PropTypes.shape({
    cfoYourSelection: PropTypes.string,
    guiPromocodeTitle: PropTypes.string,
    scaYourSelection: PropTypes.string.isRequired,
    scaMnpHeadline: PropTypes.string,
    scaMnpCopy0: PropTypes.string,
    scaMnpCopy1: PropTypes.string,
    scaBookableOptions: PropTypes.string,
    guiFeeConnection: PropTypes.string,
    guiFeeSingular: PropTypes.string,
    guiFeeSingularPayment: PropTypes.string,
    guiContract24Months: PropTypes.string.isRequired,
    guiContractNone: PropTypes.string.isRequired,
    guiContractMonthly: PropTypes.string.isRequired,
    guiSymbolGigabyte: PropTypes.string.isRequired,
    myVvlTariffHint: PropTypes.string.isRequired,
    guiSumTotal: PropTypes.string.isRequired,
    scaOrderNow: PropTypes.string.isRequired,
    scaShippingHeadline: PropTypes.string.isRequired,
    scaShippingInfo: PropTypes.string.isRequired,
    guiDeliveryState1: PropTypes.string.isRequired,
    guiDeliveryState2: PropTypes.string.isRequired,
    guiDeliveryState3: PropTypes.string.isRequired,
    guiEmptyCart: PropTypes.string.isRequired,
    guiPucDeliveryTimeHint: PropTypes.string.isRequired,
    guiPuaDeliveryTimeHint: PropTypes.string.isRequired,
    guiWordGratis: PropTypes.string.isRequired,
    guiShippingCosts: PropTypes.string,
    myVvlEsimHintHeadline: PropTypes.string,
    myVvlEsimHintCopy: PropTypes.string,
    scaEsimHintHeadline: PropTypes.string,
    scaEsimHintCopyPua: PropTypes.string,
    scaChooseSimtypeOptionClassic: PropTypes.string,
    scaChooseSimtypeOptionEsim: PropTypes.string,
    scaDeviceCheckLink: PropTypes.string,
    scaDeviceCheckHint: PropTypes.string,
    scaSimtypeOptionHeadline: PropTypes.string,
    guiWordAllright: PropTypes.string,
    mySimrepChooseSimtypeOptionEsim: PropTypes.string,
    mySimrepChooseSimtypeOptionClassic: PropTypes.string,
    txtMySimrepSimtypeHint: PropTypes.string,
    mySimrepSimtypeTrippleSim: PropTypes.string,
    scaTripleSimImage: PropTypes.string,
    sca1078Exception: PropTypes.string,
  }).isRequired,
};

ShoppingCart.defaultProps = {
  theme: 'full',
  optionsList: [],
  selectedOptions: [],
  promoIncentives: [],
  isConfirmed: false,
  isContractRenewal: false,
  isInitiallyOpen: true,
};

const mapStateToProps = ({ site }) => ({
  hideMnp: site.hideMnp,
});

const mapDispatchToProps = (dispatch) => ({
  showSimArticleList: () => dispatch(showSimArticleListDialog()),
});

export default compose(
  connectUI(),
  connect(mapStateToProps, mapDispatchToProps),
  matchMediaConnector(['isMediaS']),
)(ShoppingCart);
