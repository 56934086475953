import React from 'react';
import initForm from '../form/FormInitializer';
import TelljaRegistration from '../../components/compositions/cashback/TelljaRegistration';
import FormConfigProps from '../../model/form/FormConfigProps';

const createFieldMap = (state, { ui }) => ({
  eMail: {
    name: 'contact_email',
    label: ui.guiFormEmail,
    hint: ui.guiFormEmailHint,
    type: 'email',
    validation: {
      isRequired: true,
      pattern: 'email',
    },
  },
  eMailRepeat: {
    name: 'email_confirm',
    label: ui.guiFormEmailRepeat,
    type: 'email',
    validation: {
      isRequired: true,
      equals: ['contact_email'],
    },
  },
});

// init component once, outside
const component = initForm()(TelljaRegistration);

export const id = 'data';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  id,
  component,
  fieldMap: createFieldMap(state, props),
  formConfigProps: new FormConfigProps({
    labelSubmit: state.ui.guiRegisterNowButton,
    withRequiredHint: false,
    additionalButton: <></>,
  }),
});

export default mapStateToFormConfig;
