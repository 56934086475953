import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FormManager from '../form/FormManager';
import * as inputData from './formConfigTelljaRegistration';
import TelljaRegistrationRequest from '../../model/requests/TelljaRegistrationRequest';
import { TelljaContext } from '../../components/compositions/cashback/CachbackProvider';
import suitcss from '../../helpers/suitcss';

class TelljaRegistrationForm extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static contextType = TelljaContext;

  constructor(props, ...args) {
    super(props, ...args);
    this.onAfterSubmitSuccess = this.onAfterSubmitSuccess.bind(this);
    this.getSubmitRoute = this.getSubmitRoute.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  mapToTelljaData = (data, email) => {
    const telljaDataRegister = {};
    // eslint-disable-next-line array-callback-return
    data.legalTexts.legalText.map((legalText) => {
      if (legalText.type === 'DPA') {
        telljaDataRegister.dpaId = legalText.id;
      }
      if (legalText.type === 'DPP') {
        telljaDataRegister.dppId = legalText.id;
      }
      if (legalText.type === 'TAC') {
        telljaDataRegister.tacId = legalText.id;
      }
    });
    telljaDataRegister.referrerEmail = email;
    telljaDataRegister.widgetOpenedTime = data.widgetOpenedTime;
    telljaDataRegister.sessionId = data.sessionId;
    return telljaDataRegister;
  };

  onAfterSubmitSuccess({ responseBody }) {
    const { setReferral } = this.context;
    setReferral(responseBody.referral);
    const { hideDialog, dispatch } = this.props;
    dispatch(hideDialog());
  }

  getSubmitRoute(fieldMap, finalizedFormValues) {
    const { telljaData } = this.props;
    const { advId } = this.context;
    // eslint-disable-next-line max-len
    return new TelljaRegistrationRequest(advId, this.mapToTelljaData(telljaData, finalizedFormValues.contact_email));
  }

  render() {
    return (
      <div className={suitcss({ componentName: 'TelljaRegistrationForm' })} >
        <FormManager
          form={TelljaRegistrationForm.formName}
          stepProps={this.props}
          submitRoute={this.getSubmitRoute}
          onAfterSubmitSuccess={this.onAfterSubmitSuccess}
          steps={[inputData]}
          withBlockError
          withFooter
        />
      </div>
    );
  }
}

/**
 * required by tracking!
 */
TelljaRegistrationForm.formName = 'contact';

TelljaRegistrationForm.propTypes = {
  telljaData: PropTypes.shape({
    legalTexts: PropTypes.shape({
      legalText: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        bypassUserAcceptance: PropTypes.bool.isRequired,
        displayTextInUi: PropTypes.bool.isRequired,
      })).isRequired,
      additionalText: PropTypes.shape({
        imprint: PropTypes.string.isRequired,
        faq: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    widgetOpenedTime: PropTypes.string.isRequired,
    sessionId: PropTypes.string.isRequired,
  }),
  dispatch: PropTypes.func.isRequired,
  hideDialog: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(TelljaRegistrationForm);
