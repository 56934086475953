import QueueableRequest from './QueueableRequest';
import { bindParamsToRoute } from '../../helpers/url';
import { CASHBACK_TELLJA } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';

export default class TelljaRegistrationRequest extends QueueableRequest {
  constructor(advId, telljaData) {
    super(bindParamsToRoute(CASHBACK_TELLJA, { advId }), {
      payload: telljaData,
      method: REQUEST_METHOD_POST,
    });
  }
}
