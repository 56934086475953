import React, { useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TelljaContext } from './CachbackProvider';
import FormOptionBox from '../../basics/form/FormOptionBox';
import { showTelljaRegistrationDialog } from '../../../actions/dialog/misc';
import Headline from '../../basics/text/TextHeadline';
import TextRaw from '../../basics/text/TextRaw';
import suitcss from '../../../helpers/suitcss';
import ShoppingCartRow from '../cart/ShoppingCartRow';
import SvgLoader from '../../basics/media/MediaSvgLoader';
import TextCopy from '../../basics/text/TextCopy';
import TelljaOrderTracking from './TelljaOrderTracking';

const TelljaShoppingCart = ({
  showRegistration,
  ui,
  theme,
  tariff,
  isCheckoutSummary,
}) => {
  const {
    deleteReferral,
    cashbackActive,
    advId,
    isAvailableForTariff,
  } = useContext(TelljaContext);

  if (!isAvailableForTariff(tariff)) {
    deleteReferral();
    return <></>;
  }

  const change = () => {
    if (cashbackActive) {
      deleteReferral();
    } else {
      showRegistration(advId);
    }
  };

  if (isCheckoutSummary) {
    return <TelljaOrderTracking tariff={tariff} />;
  }

  if (theme === 'compact') {
    if (cashbackActive) {
      return (
        <ShoppingCartRow
          className={suitcss({ componentName: 'TelljaShoppingCart' })}
          theme={theme}
          isHighlighted={theme === 'full'}
          withoutPrices
        >
          <strong>{ui.telljaCashbackCartSuccess}</strong>
          <SvgLoader path="/icons/content-check.svg" />
          <TextCopy size="secondary" embedded>
            <TextRaw>{ui.txtTelljaCashbackCartInfo}</TextRaw>
          </TextCopy>
        </ShoppingCartRow>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      <ShoppingCartRow
        className={suitcss({ componentName: 'ShoppingCart', descendantName: 'promotionOption', modifiers: ['tellja'] })}
        theme="full"
        isHighlighted
        withoutPrices
        isAdjacentBottom={cashbackActive}
      >
        <Headline size="xs" utilities={['weightBold']}>
          {ui.telljaCashbackCartHeadline}
        </Headline>

        <FormOptionBox
          id="telljaCashback"
          label={ui.txtTelljaCashbackCartLabel}
          value={cashbackActive}
          checked={cashbackActive}
          onChange={change}
        />
      </ShoppingCartRow>
      {cashbackActive && (
        <ShoppingCartRow
          isAdjacentTop
          theme="full"
          className={suitcss({
            componentName: 'ShoppingCart',
            descendantName: 'promotionOption',
            modifiers: ['tellja-success'],
          })}
        >
          <SvgLoader path="/icons/content-check.svg" />
          <TextRaw>
            {ui.txtTelljaCashbackCartSuccess}
          </TextRaw>
        </ShoppingCartRow>
      )}
    </>
  );
};

TelljaShoppingCart.propTypes = {
  theme: PropTypes.string,
  ui: PropTypes.object,
  tariff: PropTypes.object,
  showRegistration: PropTypes.func.isRequired,
  isCheckoutSummary: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({
  showRegistration: (advId) => {
    dispatch(showTelljaRegistrationDialog(advId));
  },
});

export default connect(null, mapDispatchToProps)(TelljaShoppingCart);
