/* global window, document */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'event-listener-with-options/js/polyfill';
import 'intersection-observer'; // optional polyfill
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import { AppContainer } from 'react-hot-loader';

import App from './App';
import { startApp } from './config/boot';

const mountApp = (store, history) => {
  if (process.browser && window.dbn) {
    if (window.dbn.Environment) {
      const { Environment, git } = window.dbn;

      if (Environment.variables.DBN_SENTRY_ENABLED) {
        Sentry.init({
          dsn: Environment.variables.DBN_SENTRY_KEY_CSR,
          release: git.commit ? git.commit : git.hash,
          environment: Environment.variables.DBN_SENTRY_ENVIRONMENT,
          integrations: [Sentry.browserTracingIntegration()],
          tracesSampleRate: parseFloat(Environment.variables.SENTRY_TRACE_RATE || 0.0),
        });

        Sentry.setContext('Clientside', {
          renderType: 'Client side rendering',
        });

      } else {
        console.error('Can not initiate Sentry -> needed configuration missing');
      }
    }
  }

  const _render = (Root) => {
    ReactDOM.hydrate(
      <Sentry.ErrorBoundary fallback="An error has occured">
        <AppContainer>
          <Root
            store={store}
            history={history}
          />
        </AppContainer>
      </Sentry.ErrorBoundary>,
      document.querySelector('[data-app="main"]'),
    );
  };

  _render(App);

  // log the store into the page for easy access
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./App', () => {
      // eslint-disable-next-line global-require
      _render(require('./App').default);
    });
  }
};


startApp(mountApp, window.INITIAL_STATE);

