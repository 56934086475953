import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TelljaContext } from './CachbackProvider';
import suitcss from '../../../helpers/suitcss';
import FieldInput from '../../basics/form/FieldInput';
import FormSection from '../form/FormSection';
import Headline from '../../basics/text/TextHeadline';
import TextRaw from '../../basics/text/TextRaw';
import Copy from '../../basics/text/TextCopy';

class TelljaRegistration extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static contextType = TelljaContext;

  // eslint-disable-next-line no-useless-constructor
  constructor(props, context) {
    super(props, context);
  }

  mapToTelljaUrl = (data) => {
    const telljaDataUrl = {};
    // eslint-disable-next-line array-callback-return
     data.legalTexts.legalText.map((legalText) => {
      if (legalText.type === 'DPA') {
        telljaDataUrl.dpaUrl = legalText.url;
      }
      if (legalText.type === 'DPP') {
        telljaDataUrl.dppUrl = legalText.url;
      }
      if (legalText.type === 'TAC') {
        telljaDataUrl.tacUrl = legalText.url;
      }
    });
    return telljaDataUrl;
  }

  render() {
    const componentName = 'TelljaRegistrationForm';
    const { fieldMap, ui, telljaData } = this.props;
    const headline = ui.txtTelljaCashbackRegistrationHeadline;
    const header = ui.txtTelljaCashbackRegistrationHeader;
    const required = ui.guiFormRequiredFieldsAlternative;
    const emailForm = ui.guiFormEmailAlternative;
    const emailRepeatForm = ui.guiFormEmailRepeatAlternative;
    const telljaURL = this.mapToTelljaUrl(telljaData);
    const hint = ui.txtTelljaCashbackRegistrationHint.replace('{TELLJA_GENERAL_TERMS}', telljaURL.tacUrl).replace('{TELLJA_PRIVACY_POLICY}', telljaURL.dpaUrl).replace('{TELLJA_PRIVACY_POLICY_HINT}', telljaURL.dppUrl);
    const footer = ui.txtTelljaCashbackRegistrationFooter;
    return (
      <FormSection className={suitcss({ componentName })} asGrid withoutBorder>

        <Headline embedded size="m" utilities={['weightBold']}>
          <TextRaw stripPTag="p">
            {headline}
          </TextRaw>
        </Headline>
        {/* eslint-disable-next-line react/style-prop-object */}
        <TextRaw stripPTag="p">
          {header}
        </TextRaw>
        <Copy
          className={suitcss({
            componentName,
            descendantName: 'required',
          }, this)}
          element="div"
          size="secondary"
        >
          {required}
        </Copy>
        <div className={suitcss({ utilities: ['col12'] })}>
          <Copy
            className={suitcss({
              utilities: ['col12'],
            }, this)}
            element="span"
            embedded
          >
            {emailForm}
          </Copy>
          <Field {...fieldMap.eMail} component={FieldInput} utilities={['col12']} withToolTip />
        </div>
        <div className={suitcss({ utilities: ['col12'] })}>
          <Copy
            className={suitcss({
              utilities: ['col12'],
            }, this)}
            element="span"
            embedded
          >
            {emailRepeatForm}
          </Copy>
          <Field {...fieldMap.eMailRepeat} component={FieldInput} utilities={['col12']} withToolTip />
          <Copy
            className={suitcss({
              componentName,
              descendantName: 'hint',
              utilities: ['colorGray100'],
            }, this)}
            element="div"
            size="secondary"
            embedded
          >
            <TextRaw stripPTag="p">
              {hint}
            </TextRaw>
          </Copy>
        </div>

        <Copy
          className={suitcss({
            componentName,
            descendantName: 'hint',
          }, this)}
          element="div"
          size="secondary"
          embedded
        >
          <TextRaw stripPTag="p">
            {footer}
          </TextRaw>
        </Copy>
      </FormSection>
    );
  }
  }

  TelljaRegistration.propTypes = {
    telljaData: PropTypes.shape({
    legalTexts: PropTypes.shape({
      legalText: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        bypassUserAcceptance: PropTypes.bool.isRequired,
        displayTextInUi: PropTypes.bool.isRequired,
      })).isRequired,
      additionalText: PropTypes.shape({
        imprint: PropTypes.string.isRequired,
        faq: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    widgetOpenedTime: PropTypes.string.isRequired,
    sessionId: PropTypes.string.isRequired,
    }),
  fieldMap: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps)(TelljaRegistration);
