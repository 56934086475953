import {
  ROUTE_PREFIX_MY_OTELO,
} from '../helpers/constants';

// the closure function is required to encapsulate dead code so it can be removed via uglify
// (clients does not need to know about environment variables for the NodeJS server)
export const API_HOSTNAME = (() => {
  // on the server we pass the hostnames as environment variables
  if (process.env.NODE_API_PROTOCOL && process.env.NODE_API_HOST) {
    const ENV_API_PROTOCOL = process.env.NODE_API_PROTOCOL;
    const ENV_API_HOST = process.env.NODE_API_HOST;
    const ENV_API_HOSTNAME = `${ENV_API_PROTOCOL}://${ENV_API_HOST}/`;
    return process.browser ? '/' : ENV_API_HOSTNAME;
  }

  // the api hostname on the client should always be the current website to avoid issues with
  // cross origin policy
  return '/';
})();

/**
 * Checks whether the endoint is secured and requires sequential
 * loading and authentication headers.
 */
export const isAccountEndpoint = url => {
  const result = url.indexOf('/msisdn/') !== -1 || url.indexOf('/event-gateway/events') !== -1;

  return result;
};

/**
 * Checks whether a url entered by a user leads to an account related page.
 * @todo remove deprecated route after mein-otelo launch
 */
export const isAccountUrl = location =>
  location && location.pathname.startsWith(ROUTE_PREFIX_MY_OTELO);
/* eslint-disable max-len, no-multi-spaces */

/**
 * Account dependent endpoints; require login
 */
export const ACCOUNT_BALANCE_ENDPOINT                  = `${API_HOSTNAME}api/v2/msisdn/:msisdn/balance`;
export const ACCOUNT_CONSUMPTIONS_ENDPOINT             = `${API_HOSTNAME}api/v2/msisdn/:msisdn/consumptions`;
export const ACCOUNT_CONSUMPTIONS_DETAILS_ENDPOINT     = `${API_HOSTNAME}api/v2/msisdn/:msisdn/consumptions/details`;
export const ACCOUNT_TARIFF_SUMMARY_ENDPOINT           = `${API_HOSTNAME}api/v2/msisdn/:msisdn/tariff-summary`;
export const ACCOUNT_BOOKING_OVERVIEW_ENDPOINT         = `${API_HOSTNAME}api/v2/msisdn/:msisdn/booking-overview`;
export const ACCOUNT_CONTRACT_DATA_ENDPOINT            = `${API_HOSTNAME}api/v2/msisdn/:msisdn/contract-data`;
export const ACCOUNT_CONSUMPTIONS_FLATRATES_ENDPOINT   = `${API_HOSTNAME}api/v2/msisdn/:msisdn/consumptions/flatrates`;
export const ACCOUNT_CUSTOMER_DATA_ENDPOINT            = `${API_HOSTNAME}api/v2/msisdn/:msisdn/customer-data`;
export const ACCOUNT_CUSTOMER_INVOICE_CHANNEL_ENDPOINT = `${API_HOSTNAME}api/v2/msisdn/:msisdn/customer/invoice-channel`;
export const ACCOUNT_CUSTOMER_CONTACT_ENDPOINT         = `${API_HOSTNAME}api/v2/msisdn/:msisdn/customer/contact`;
export const ACCOUNT_CUSTOMER_AVATAR_ENDPOINT          = `${API_HOSTNAME}api/v2/msisdn/:msisdn/customer/avatar`;
export const ACCOUNT_CUSTOMER_EMAIL_ENDPOINT           = `${API_HOSTNAME}api/v2/msisdn/:msisdn/customer/email`;
export const ACCOUNT_EVN_SETTING_ENDPOINT              = `${API_HOSTNAME}api/v2/msisdn/:msisdn/evn-setting`;
export const ACCOUNT_PAYMENT_DATA_ENDPOINT             = `${API_HOSTNAME}api/v2/msisdn/:msisdn/payment-data`;
export const ACCOUNT_CHANGE_PASSWORD_ENDPOINT          = `${API_HOSTNAME}api/v2/msisdn/:msisdn/change-password`;
export const ACCOUNT_PAYMENT_DATA_DEBIT_ENDPOINT       = `${API_HOSTNAME}api/v2/msisdn/:msisdn/payment-data/debit`;
export const ACCOUNT_PAYMENT_DATA_CREDIT_CARD_ENDPOINT = `${API_HOSTNAME}api/v2/msisdn/:msisdn/payment-data/credit_card`;
export const ACCOUNT_TARIFFS_ENDPOINT                  = `${API_HOSTNAME}api/v2/msisdn/:msisdn/tariffs`;
export const ACCOUNT_TARIFF_ENDPOINT                   = `${API_HOSTNAME}api/v2/msisdn/:msisdn/tariffs/:tariffId`;
export const ACCOUNT_TARIFF_OPTIONS_ENDPOINT           = `${API_HOSTNAME}api/v2/msisdn/:msisdn/tariff-options`;
export const ACCOUNT_TARIFF_OPTION_ENDPOINT            = `${API_HOSTNAME}api/v2/msisdn/:msisdn/tariff-options/:tariffOptionId`;
export const ACCOUNT_PROMOTIONS_ENDPOINT               = `${API_HOSTNAME}api/v2/msisdn/:msisdn/promotions`;
export const ACCOUNT_PROMOTION_ENDPOINT                = `${API_HOSTNAME}api/v2/msisdn/:msisdn/promotions/:promotionId`;
export const ACCOUNT_PROMOTION_BANNER_ROTATION         = `${API_HOSTNAME}api/v2/msisdn/:msisdn/marketing/banner/dashboard`;
export const ACCOUNT_DOCUMENT_CONTENT_ENDPOINT         = `${API_HOSTNAME}api/v2/msisdn/:msisdn/documents/:did`;
export const ACCOUNT_DOCUMENTS_ENDPOINT                = `${API_HOSTNAME}api/v2/msisdn/:msisdn/documents`;
export const ACCOUNT_EMAIL_VALIDATION_ENDPOINT         = `${API_HOSTNAME}api/v2/msisdn/:msisdn/email-validation`;
export const ACCOUNT_TOPUP_SINGLE_ENDPOINT             = `${API_HOSTNAME}api/v2/msisdn/:msisdn/topup/single`;
export const ACCOUNT_TOPUP_VOUCHER_ENDPOINT            = `${API_HOSTNAME}api/v2/msisdn/:msisdn/topup/voucher`;
export const ACCOUNT_TOPUP_STATUS_ENDPOINT             = `${API_HOSTNAME}api/v2/msisdn/:msisdn/topup/status`;
export const ACCOUNT_TOPUP_AUTO_ENDPOINT               = `${API_HOSTNAME}api/v2/msisdn/:msisdn/topup/auto`;
export const ACCOUNT_NOTIFICATIONS_ENDPOINT            = `${API_HOSTNAME}api/v2/msisdn/:msisdn/notifications/:notificationId`;
export const ACCOUNT_ESIM_READY_TO_PAIR_ENDPOINT       = `${API_HOSTNAME}api/v2/msisdn/:msisdn/notifications/esim-ready-to-pair`;
export const ACCOUNT_CANCELLATION_ENDPOINT             = `${API_HOSTNAME}api/v2/msisdn/:msisdn/contracts/:contractId/cancellation`;
export const ACCOUNT_MNP_STATUS_ENDPOINT               = `${API_HOSTNAME}api/v2/msisdn/:msisdn/mnp/:portingMsisdn`;
export const ACCOUNT_MNP_OUT_ENDPOINT                  = `${API_HOSTNAME}api/v2/msisdn/:msisdn/mnp-out`;
export const ACCOUNT_MNP_OUT_DATA_ENDPOINT             = `${API_HOSTNAME}api/v2/msisdn/:msisdn/mnp-out:format`;
export const ACCOUNT_SUBSCRIPTION_ENDPOINT             = `${API_HOSTNAME}api/v2/msisdn/:msisdn/contracts/:contractId/subscription`;
export const ACCOUNT_SIM_ENDPOINT                      = `${API_HOSTNAME}api/v2/msisdn/:msisdn/sim-card`;
export const ACCOUNT_SIM_PASSIVATE_ENDPOINT            = `${API_HOSTNAME}api/v2/msisdn/:msisdn/sim-card/passivate`;
export const ACCOUNT_SIM_REPL_ENDPOINT                 = `${API_HOSTNAME}api/v2/msisdn/:msisdn/replacement-sim-card`;
export const ACCOUNT_SIM_REPL_ACTIVATION_ENDPOINT      = `${API_HOSTNAME}api/v2/msisdn/:msisdn/replacement-sim-card/activation`;
export const ACCOUNT_SIM_REPL_ORDER_ENDPOINT           = `${API_HOSTNAME}api/v2/msisdn/:msisdn/replacement-sim-card/order`;
export const ACCOUNT_GDPR_CONSENTS_ENDPOINT            = `${API_HOSTNAME}api/v2/msisdn/:msisdn/gdpr/consents`;
export const ACCOUNT_GDPR_CONSENTS_AGGREGATED_ENDPOINT = `${API_HOSTNAME}api/v2/msisdn/:msisdn/gdpr/consents/aggregated`;
export const ACCOUNT_GDPR_CONSENTS_INQUIRY_ENDPOINT = `${API_HOSTNAME}api/v2/msisdn/:msisdn/gdpr/consents/aggregated/inquiry`;
export const ACCOUNT_DATA_PORTABILTY_ENDPOINT          = `${API_HOSTNAME}api/v2/msisdn/:msisdn/gdpr/data-portability:format`;
export const ACCOUNT_LOGOUT_USER_ENDPOINT              = `${API_HOSTNAME}api/v2/authenticate/logout`;
export const ACCOUNT_ACR_SETTINGS_ENDPOINT             = `${API_HOSTNAME}api/v2/msisdn/:msisdn/service-options`;
export const ACCOUNT_NBA_OFFER_ENDPOINT               = `${API_HOSTNAME}api/v2/msisdn/:msisdn/offers/nba`;

// esim
export const ACCOUNT_SIM_CARD_ESIM_ENDPOINT            = `${API_HOSTNAME}api/v2/msisdn/:msisdn/sim-card/esim`;
export const ACCOUNT_SIM_CARD_ESIM_QR_ENDPOINT         = `${API_HOSTNAME}api/v2/msisdn/:msisdn/sim-card/esim/qr`;
export const ACCOUNT_SIM_CARD_ESIM_PUSH_ENDPOINT       = `${API_HOSTNAME}api/v2/msisdn/:msisdn/sim-card/esim/push`;
export const ACCOUNT_SIM_CARD_ESIM_STATUS_ENDPOINT     = `${API_HOSTNAME}api/v2/msisdn/:msisdn/sim-card/esim/status`;

/*
 * Public endpoints
 */
export const AVATARS_ENDPOINT                          = `${API_HOSTNAME}api/v2/avatars`;
export const TAGS_ENDPOINT                             = `${API_HOSTNAME}api/v2/tags`;
export const SITEMAP_ENDPOINT                          = `${API_HOSTNAME}api/v2/sitemap`;
export const DIALOGS_ENDPOINT                          = `${API_HOSTNAME}api/v2/dialogs`;
export const TEXTS_ENDPOINT                            = `${API_HOSTNAME}api/v2/texts`;
export const DOWNLOADS_ENDPOINT                        = `${API_HOSTNAME}api/v2/downloads`;
export const TEASER_BARS_ENDPOINT                      = `${API_HOSTNAME}api/v2/teaser-bars`;
export const LOGGING_ENDPOINT                          = `${API_HOSTNAME}api/v2/log/:level`;
export const AUTHENTICATE_ENDPOINT                     = `${API_HOSTNAME}api/v2/authenticate`;
export const REAUTHENTICATE_ENDPOINT                   = `${API_HOSTNAME}api/v2/reauthenticate/:msisdn`;
export const FREE_SIM_ORDERING_ENDPOINT                = `${API_HOSTNAME}api/v2/market/:market/order/free_sim`;
export const CHECKOUT_SUBMIT_ENDPOINT                  = `${API_HOSTNAME}api/v2/market/:market/order`;
export const ACTIVATION_CHECK_ENDPOINT                 = `${API_HOSTNAME}api/v2/activation/check`;
export const ARTICLE_INFORMATION_GROUPS_ENDPOINT       = `${API_HOSTNAME}api/v2/article-information/article-groups`;
/*
`/api/v2/market/:market/flexmode-token/cc`;
`/api/v2/market/:market/flexmode-token/paypal`;
`/api/v2/market/:market/flexmode-token/:paymentType`;
*/
export const FLEXMODE_TOKEN_ENDPOINT_CREATE            = `${API_HOSTNAME}api/v2/market/:market/flexmode-token`;
export const FLEXMODE_TOKEN_ENDPOINT_INFO              = `${API_HOSTNAME}api/v2/market/:market/flexmode-token/:token`;
export const CREATE_FLEXMODE_TOKEN_ENDPOINT            = `${API_HOSTNAME}api/v2/market/:market/flexmode-token/:paymentType`;
export const PROMOTION_ENDPOINT                        = `${API_HOSTNAME}api/v2/promotions`;
export const MOBILE_PROVIDERS_ENDPOINT                 = `${API_HOSTNAME}api/v2/market/:market/mnp/providers`;
export const FAQ_TOPIC_ENDPOINT                        = `${API_HOSTNAME}api/v2/faqs/:topic`;
export const PAGE_WEBSITE_ENDPOINT                     = `${API_HOSTNAME}api/v2/page/:fragment`;
export const PAGE_ERROR_ENDPOINT                       = `${API_HOSTNAME}api/v2/error-page/:httpStatus`;
export const ENTITY_ENDPOINT                           = `${API_HOSTNAME}api/v2/entity/:eid`;
export const MENU_ENDPOINT                             = `${API_HOSTNAME}api/v2/menu/:id`;
export const UI_ELEMENTS_ENDPOINT                      = `${API_HOSTNAME}api/v2/ui_elements/:id`;
export const LEGALS_ENDPOINT                           = `${API_HOSTNAME}api/v2/legal`;
export const CALLOUT_ENDPOINT                          = `${API_HOSTNAME}api/v2/callouts`;
export const CONTACT_ENDPOINT                          = `${API_HOSTNAME}api/v2/contact`;
export const PASSWORD_RESET_VIA_EMAIL_ENDPOINT         = `${API_HOSTNAME}api/v2/password-reset/email`;
export const PASSWORD_RESET_VIA_SMS_ENDPOINT           = `${API_HOSTNAME}api/v2/password-reset/sms`;
export const PASSWORD_RESET_OPTIONS_ENDPOINT           = `${API_HOSTNAME}api/v2/password-reset/options`;
export const PASSWORD_RESET_ENDPOINT                   = `${API_HOSTNAME}api/v2/password-reset/set`;
export const ID_MAP_ENDPOINT                           = `${API_HOSTNAME}api/v2/id-map`;
export const NPS_SUBMIT_ENDPOINT                       = `${API_HOSTNAME}api/v2/online-feedback/:optionType`;
export const PING_ENDPOINT                             = `${API_HOSTNAME}api/v2/ping`;
export const COUNTRIES_ENDPOINT                        = `${API_HOSTNAME}api/v2/market/:market/countries`;
export const GDPR_CONSENTS_ENDPOINT                    = `${API_HOSTNAME}api/v2/gdpr/consents`;
export const UMID_INFO_ENDPOINT                        = `${API_HOSTNAME}api/v2/funnel-connect/info/:identifier`;
export const PASSWORD_VALIDATION_RULES_ENDPOINT        = `${API_HOSTNAME}api/v3/validation/rules`;
export const EMAIL_VALIDATION_CONFIRM                  = `${API_HOSTNAME}api/v2/email-validation/confirm`;
export const EMAIL_REGISTRATION_ENDPOINT               = `${API_HOSTNAME}api/v2/email-registration`;
export const SUBSCRIPTION_MANAGEMENT_ENDPOINT          = `${API_HOSTNAME}api/v2/service-subscription/:subscriptionType/:solicitorId`;
export const TRIGGER_EVENT_ENDPOINT                    = `${API_HOSTNAME}api/v2/event-gateway/events`;

export const CUMULATIVE_CONSUMPTION_ENDPOINT           = `${API_HOSTNAME}api/v2/msisdn/:msisdn/cumulative-consumption/cost`;

export const CONTRACT_SUMMARY_DOCUMENT_ENDPOINT        = `${API_HOSTNAME}api/v2/msisdn/:msisdn/contract-summary/document`;

export const SEARCH_SERVICE_ENDPOINT                   = `${API_HOSTNAME}api/v2/search`;
export const SEARCH_SUGGESTION_ENDPOINT                = `${API_HOSTNAME}api/v2/search/suggest`;

export const EECC_ACTIVATION_INITIATE                  = `${API_HOSTNAME}api/v2/:msisdn/activation/initiate`;
export const EECC_ACTIVATION_DOCUMENT                  = `${API_HOSTNAME}api/v2/:msisdn/activation/:processId/document`;
export const EECC_ACTIVATION_CONFIRM                   = `${API_HOSTNAME}api/v2/:msisdn/activation/:processId/confirm`;

export const EECC_ORDERING_INITIATE                    = `${API_HOSTNAME}api/v2/order/initiate`;
export const EECC_ORDERING_DOCUMENT                    = `${API_HOSTNAME}api/v2/order/:processId/document`;
export const EECC_ORDERING_CONFIRM                     = `${API_HOSTNAME}api/v2/order/:processId/confirm`;

export const EECC_TARIFFOPTIONS_ORDER_INITIATE         = `${API_HOSTNAME}api/v2/msisdn/:msisdn/tariff-options/initiate`;
export const EECC_TARIFFOPTIONS_ORDER_DOCUMENT         = `${API_HOSTNAME}api/v2/msisdn/:msisdn/tariff-options/:processId/document`;
export const EECC_TARIFFOPTIONS_ORDER_CONFIRM          = `${API_HOSTNAME}api/v2/msisdn/:msisdn/tariff-options/:processId/confirm`;

export const EECC_PROMOTIONS_ORDER_INITIATE            = `${API_HOSTNAME}api/v2/msisdn/:msisdn/promotions/initiate`;
export const EECC_PROMOTIONS_ORDER_DOCUMENT            = `${API_HOSTNAME}api/v2/msisdn/:msisdn/promotions/:processId/document`;
export const EECC_PROMOTIONS_ORDER_CONFIRM             = `${API_HOSTNAME}api/v2/msisdn/:msisdn/promotions/:processId/confirm`;

export const EECC_TARIFFOPTIONS_DELETE_INITIATE        = `${API_HOSTNAME}api/v2/msisdn/:msisdn/tariff-options/cancel/initiate`;
export const EECC_TARIFFOPTIONS_DELETE_DOCUMENT        = `${API_HOSTNAME}api/v2/msisdn/:msisdn/tariff-options/cancel/:processId/document`;
export const EECC_TARIFFOPTIONS_DELETE_CONFIRM         = `${API_HOSTNAME}api/v2/msisdn/:msisdn/tariff-options/cancel/:processId/confirm`;

export const EECC_TARIFF_CHANGE_INITIATE = `${API_HOSTNAME}api/v2/msisdn/:msisdn/tariff-change/initiate`;
export const EECC_TARIFF_CHANGE_DOCUMENT = `${API_HOSTNAME}api/v2/msisdn/:msisdn/tariff-change/:processId/document`;
export const EECC_TARIFF_CHANGE_CONFIRM = `${API_HOSTNAME}api/v2/msisdn/:msisdn/tariff-change/:processId/confirm`;

export const EECC_BMNP_PORTING_INITIATE = `${API_HOSTNAME}api/v2/msisdn/:msisdn/bmnp/initiate`;
export const EECC_BMNP_PORTING_DOCUMENT = `${API_HOSTNAME}api/v2/msisdn/:msisdn/bmnp/:processId/document`;
export const EECC_BMNP_PORTING_CONFIRM = `${API_HOSTNAME}api/v2/msisdn/:msisdn/bmnp/:processId/confirm`;

export const FRIEND_REFERRAL_STATUS = `${API_HOSTNAME}api/v2/msisdn/:msisdn/friend-referral/status`;
export const FRIEND_REFERRAL_LINK = `${API_HOSTNAME}api/v2/msisdn/:msisdn/friend-referral/link`;
export const FRIEND_REFERRAL_HISTORY = `${API_HOSTNAME}api/v2/msisdn/:msisdn/friend-referral/history`;

export const CONTRACT_SUMMARY                          = `${API_HOSTNAME}api/v2/contract-summary/:processId/confirm-deferred`;
export const PRESALES_CANCELLATION_ENDPOINT                         = `${API_HOSTNAME}api/v2/contracts/cancellation`;

export const CAPTCHA_INITIATE = `${API_HOSTNAME}api/v2/captcha/captcha`;
export const CAPTCHA_APIKEY = 'd9f5cf31-cb45-4761-8b7d-86ac152f54f8';

export const CASHBACK_TELLJA = `${API_HOSTNAME}api/v2/cashback/:advId`;
