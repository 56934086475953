import EECCBMnpPortingInitiateRequest from '../../model/requests/EECCBMnpPortingInitiateRequest';
import BookingOverviewRequest from '../../model/requests/BookingOverviewRequest';
import ESimNotificationReadyToPairRequest
  from '../../model/requests/ESimNotificationReadyToPairRequest';
import { updateContractDataESimStatus } from '../user/eSim';
import { sendIfNecessary, send } from './send';
import { getContext } from '../../helpers/promotions';
import PaymentDataRequest from '../../model/requests/PaymentDataRequest';
import TopupStatusRequest from '../../model/requests/TopupStatusRequest';
import CustomerDataRequest from '../../model/requests/CustomerDataRequest';
import TariffRequest from '../../model/requests/TariffRequest';
import TariffOptionRequest from '../../model/requests/TariffOptionRequest';
import MyPromotionRequest from '../../model/requests/MyPromotionRequest';
import TariffsRequest from '../../model/requests/TariffsRequest';
import TariffOptionsRequest from '../../model/requests/TariffOptionsRequest';
import MyPromotionsRequest from '../../model/requests/MyPromotionsRequest';
import PromotionsRequest from '../../model/requests/PromotionsRequest';
import ConsumptionsRequest from '../../model/requests/ConsumptionsRequest';
import TariffSummaryRequest from '../../model/requests/TariffSummaryRequest';
import BalanceRequest from '../../model/requests/BalanceRequest';
import FlatratesRequest from '../../model/requests/FlatratesRequest';
import EntityRequest from '../../model/requests/EntityRequest';
import ContractDataRequest from '../../model/requests/ContractDataRequest';
import UiElementsRequest from '../../model/requests/UiElementsRequest';
import AvatarsRequest from '../../model/requests/AvatarsRequest';
import SimReplStatusRequest from '../../model/requests/SimReplStatusRequest';
import SimCardRequest from '../../model/requests/SimCardRequest';
import TagsRequest from '../../model/requests/TagsRequest';
import MenuRequest from '../../model/requests/MenuRequest';
import GdprConsentsRequest from '../../model/requests/AccountGdprConsentsRequest';
import GdprConsentsAggregatedRequest from '../../model/requests/AccountGdprConsentsAggregatedRequest';
import GdprConsentsInquiryRequest from '../../model/requests/AccountGdprConsentsInquiryRequest';
import DataPortabilityRequest from '../../model/requests/AccountDataPortabilityRequest';
import BannerRotationRequest from '../../model/requests/BannerRotationRequest';
import EmailValidationRequest from '../../model/requests/EmailValidationRequest';
import MnpOutRequest from '../../model/requests/MnpOutRequest';
import NbaOfferRequest from '../../model/requests/NbaOfferRequest';
import { getMsisdn } from '../../helpers/accessors';
import { getEntityById } from '../../selectors/misc';
import SearchServiceRequest from '../../model/requests/SearchServiceRequest';
import SearchSuggestionRequest from '../../model/requests/SearchSuggestionRequest';
import EECCOrderingInitiateRequest from '../../model/requests/EECCOrderingInitiateRequest';
import EECCActivationInitiateRequest from '../../model/requests/EECCActivationInitiateRequest';
import CumulativeConsumptionRequest from '../../model/requests/CumulativeConsumptionRequest';
import EECCTariffChangeInitiateRequest from '../../model/requests/EECCTariffChangeInitiateRequest';
import EECCPromotionsOrderInitiateRequest
  from '../../model/requests/EECCPromotionsOrderInitiateRequest';
import FriendReferralStatusRequest from '../../model/requests/FriendReferralStatusRequest';
import FriendReferralLinkRequest from '../../model/requests/FriendReferralLinkRequest';
import FriendReferralHistoryRequest from '../../model/requests/FriendReferralHistoryRequest';
import ArticleInformationGroupsRequest from '../../model/requests/ArticleInformationGroupsRequest';
import SimCardESimInformationRequest from '../../model/requests/SimCardESimInformationRequest';
import SimCardESimQRRequest from '../../model/requests/SimCardESimQRRequest';
import SimCardESimStatusRequest from '../../model/requests/SimCardESimStatusRequest';
import CaptchaInitiateRequest from '../../model/requests/CaptchaInitiateRequest';
import { registerCaptcha } from '../captcha/captcha';
import TelljaSessionRequest from '../../model/requests/TelljaRequest';
import TelljaRegistrationRequest from '../../model/requests/TelljaRegistrationRequest';

/**
 * @return {Promise} the customer data as it exists in the store
 */

export const fetchGdprConsentsAggregatedData = (options, isForce) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(
    new GdprConsentsAggregatedRequest(getMsisdn(getState()), options),
    isForce,
  ));
  return getState().user.adConsent;
};

export const fetchGdprConsentsInquiryData = requestData => (dispatch, getState) => {
  dispatch(send(new GdprConsentsInquiryRequest(getMsisdn(getState()), requestData)));
  return getState().user.gdprConsentsInquiry;
};

export const fetchGdprConsentsData = (options, isForce) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(
    new GdprConsentsRequest(getMsisdn(getState()), options), isForce),
  );
  return getState().user.gdprConsents;
};

export const fetchMnpOutData = (isForce) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(
    new MnpOutRequest(getMsisdn(getState())), isForce),
  );
  return getState().user.mnpOutData;
};


export const fetchGdprUserData = (options, isForce) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(
    new DataPortabilityRequest(getMsisdn(getState()), options), isForce),
  );
  return getState().user.gdprData;
};

/**
 * @return {Promise} the customer data as it exists in the store
 */
export const fetchCustomerData = (options, isForce) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(new CustomerDataRequest(getMsisdn(getState()), options), isForce));
  return getState().user.customerData;
};

export const fetchEmailValidationStatus = (options, isForce) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(new EmailValidationRequest(getMsisdn(
    getState()), options), isForce));
  return getState().user.customerData;
};

/**
 * @return {Promise} the customer data as it exists in the store
 */
export const fetchContractData = (options, isForce) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(new ContractDataRequest(getMsisdn(getState()), options), isForce));
  return getState().user.contractData;
};

/**
 * @return {Promise} the data volume the pua user consumes the last month
 */
export const fetchMonthlyConsumption = (options, isForce) => async (dispatch, getState) => {
  await dispatch(
    sendIfNecessary(
      new CumulativeConsumptionRequest(getMsisdn(getState()), options), isForce));

  return getState().user.cumulativeConsumption;
};

/**
 * @return {Promise} the sim card data as it exists in the store
 */
export const fetchSimCard = (options, isForce) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(new SimCardRequest(getMsisdn(getState()), options), isForce));
  return getState().user.simCard;
};

/**
 * @return {Promise} the replacement sim card data as it exists in the store
 */
export const fetchSimReplacementStatus = options => async (dispatch, getState) => {
  await dispatch(send(new SimReplStatusRequest(getMsisdn(getState()), options))); // force sending!
  return getState().user.simReplacementStatus;
};

/**
 * @return {Promise} the payment data as it exists in the store
 */
export const fetchPaymentData = (options, isForce) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(new PaymentDataRequest(getMsisdn(getState()), options), isForce));
  return getState().user.paymentData;
};

/**
 * @return {Promise} the topup data as it exists in the store
 */
export const fetchTopupStatus = (options, isForce) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(new TopupStatusRequest(getMsisdn(getState()), options), isForce));
  return getState().user.topup;
};

/**
 * @return {Promise} the consumptions data as it exists in the store
 */
export const fetchConsumptions = (options, isForce) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(new ConsumptionsRequest(getMsisdn(getState()), options), isForce));
  return getState().user.consumptions;
};

/**
 * @return {Promise} the flatrates data as it exists in the store
 */
export const fetchFlatrates = (options, isForce) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(new FlatratesRequest(getMsisdn(getState()), options), isForce));
  return getState().user.flatrates;
};

/**
 * @return {Promise} the tariffSummary data as it exists in the store
 */
export const fetchTariffSummary = (options, isForce) => async (dispatch, getState) => {
  await dispatch(
    sendIfNecessary(new TariffSummaryRequest(getMsisdn(getState()), options), isForce),
  );
  return getState().user.tariffSummary;
};

/**
 * Fetches the booking overview using the provided options and force flag.
 * @param {Object} options - The options for fetching the booking overview.
 * @param {boolean} isForce - Whether to force the fetching of the booking overview.
 * @return {Object} The booking overview data fetched from the state.
 */
export const fetchBookingOverview = (options, isForce) => async (dispatch, getState) => {
  await dispatch(
    sendIfNecessary(new BookingOverviewRequest(getMsisdn(getState()), options), isForce),
  );
  return getState().user.bookingOverview;
};

/**
 * @return {Promise} the balance data as it exists in the store
 */
export const fetchBalance = (options, isForce) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(new BalanceRequest(getMsisdn(getState()), options), isForce));
  return getState().user.balance;
};

/**
 * @return {Promise} The banners
 */
export const fetchPromoBanners = (options, isForce) => async (dispatch, getState) => {
  const request = new BannerRotationRequest(getMsisdn(getState()));
  await dispatch(sendIfNecessary(request, isForce));
  return getState().user.banners;
};

/**
 * @return {Promise} The nba offer
 */
export const fetchNbaOffer = (options, isForce) => async (dispatch, getState) => {
  const request = new NbaOfferRequest(getMsisdn(getState()));
  try {
    await dispatch(sendIfNecessary(request, isForce));
  } catch (err) {
    // Handle 404 error without logging to Sentry, because in this case 404 is not an error.
    // 404 indicates that there are no nba offers available.
    Error(err);
  }
  return getState().user.nbaOffer;
};

/**
 * @return {Promise} Map from internal-ids to entity-ids
 */
export const fetchUiElements = (ids, options) => dispatch =>
  Promise.all(ids.map(id => dispatch(sendIfNecessary(new UiElementsRequest(id, options)))));

export const fetchMenuElements = ids => dispatch =>
  Promise.all(ids.map(id => dispatch(sendIfNecessary(new MenuRequest(id)))));

/**
 * @return {Promise} the requested data as it is stored in the redux store
 */
export const fetchAvatars = (options) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(new AvatarsRequest(options)));
  return getState().site.avatars;
};

/**
 * @return {Promise} the requested data as it is stored in the redux store
 */
export const fetchTags = (options) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(new TagsRequest(options)));
  return getState().site.tags;
};

/**
 * Fetches all available promotions, regardless whether the user is actually
 * allowed to book them or not.
 *
 * Note: this is always force fetching promos!
 * @return {Promise} the requested data as it is stored in the redux store
 */
export const fetchPromotions = (options, isForce = true) =>
  async (dispatch, getState) => {
    const state = getState();
    const { user } = state;
    const { bId, promoCodes = [], campaignToken = [] } = user;
    const request = new PromotionsRequest(
      getContext(state),
      bId,
      promoCodes[0],
      campaignToken[0],
      options,
    );

    await dispatch(sendIfNecessary(request, isForce));
    const { site, entities } = getState();
    return {
      ...site.optionalPromotions.map(eid => entities.promotionVO[eid]),
      ...site.bookablePromotions.map(eid => entities.promotionVO[eid]),
    };
  };

/**
 * Fetches granted promotions, Type promoCode etc.
 *
 * Note: this is always force fetching promos!
 * @return {Promise} the requested data as it is stored in the redux store
 */
export const fetchGrantedPromotions = (options, isForce = true) => async (dispatch, getState) => {
  await dispatch(fetchPromotions(options, isForce));
  const { user, entities } = getState();
  return user.grantedPromotions.promotions.map(eid => entities.promotionVO[eid]);
};

export const fetchGrantedCampaignPromotions = (options, isForce = true) =>
  async (dispatch, getState) => {
    await dispatch(fetchPromotions(options, isForce));
    const { user, entities } = getState();
    return user.grantedCampaignPromotions.map(eid => entities.promotionVO[eid]);
  };

/**
 * Fetches a selfcare promotion per internal id (iid).
 * @return {Promise} a promotionVO
 */
export const fetchMyPromotion = (iid, options, isForce) => async (dispatch, getState) => {
  const request = new MyPromotionRequest(getMsisdn(getState()), iid, options);
  await dispatch(sendIfNecessary(request, isForce));
  const { entities } = getState();
  return Object.values(entities.promotionVO).find(entry => entry.id === iid);
};

/**
 * @return {Promise} a list of selfcare promotion entities
 */
export const fetchMyPromotions = (options, isForce = true) => async (dispatch, getState) => {
  const state = getState();
  const { user, entities } = state;
  const { promoCodes = [] } = user;
  // according to DBNHAMOP-4465 vvl should be able to accept promoCodes
  const request = new MyPromotionsRequest(
    getMsisdn(getState()),
    getContext(state),
    promoCodes[0],
    options,
  );
  await dispatch(sendIfNecessary(request, isForce));
  return user.bookablePromotions.map(eid => entities.promotionVO[eid]);
};

/**
 * @return {Promise} a tariffOptionVO
 */
export const fetchTariffOption = (id, options, isForce) => async (dispatch, getState) => {
  const request = new TariffOptionRequest(getMsisdn(getState()), id, options);
  await dispatch(sendIfNecessary(request, isForce));
  const { entities } = getState();
  return entities.tariffOptionVO[id];
};

/**
 * @return {Promise} a tariffVO
 */
export const fetchTariff = (id, options, isForce) => async (dispatch, getState) => {
  const request = new TariffRequest(getMsisdn(getState()), id, options);
  await dispatch(sendIfNecessary(request, isForce));
  const { entities } = getState();
  return entities.tariffVO[id];
};

/**
 * @return {Promise} a list of tarriffOption entities grouped by their category
 */
export const fetchTariffOptions = (options, isForce) => async (dispatch, getState) => {
  const request = new TariffOptionsRequest(getMsisdn(getState()), options);
  await dispatch(sendIfNecessary(request, isForce));
  const { user, entities } = getState();
  const optionCategories = ['bookedTariffOptions', 'bookableTariffOptions'];
  return Object.assign({}, ...optionCategories.map(optionCategory => ({
    [optionCategory]: user[optionCategory].map(iid => entities.tariffOptionVO[iid]),
  })));
};

/**
 * Fetches the an Entity based on the given entityId from store or from server. The entity
 * is also stored in the store (`REQUEST_RECEIVED_RESPONSE` is processed in entites reducer).
 *
 * @param {string} entityId of the entity (e.g. N41).
 * @param {boolean} [isForce=false] - fetch from server even if data is already in our store.
 * @param {object} options - request options
 * @return {Promise} the resolved entity
 */
export const fetchEntityById =
  (entityId, isForce, options = { isBlocking: true }) =>
    async (dispatch, getState) => {
      if (!isForce) {
        // a little optimizer to prevent fetching of entities that already lie in our store but
        // are not registered in the request history (because they were included in the payload
        // of another request)
        const entityInStore = getEntityById(getState().entities, entityId);
        if (entityInStore) {
          return entityInStore;
        }
      }

      await dispatch(sendIfNecessary(new EntityRequest(entityId, options), isForce));
      return getEntityById(getState().entities, entityId);
    };

export const fetchEntitiesByIds = (ids, isForce, options) => (dispatch) => {
  return Promise.all(ids.map(async eid => {
    const entity = await dispatch(fetchEntityById(eid, isForce, options));
    return entity;
  }));
};

/**
 * Fetches all bookable and booked tariffsVO from store or from server.
 *
 * @param {Object} options
 * @param {boolean} [isForce=false] - fetch from server even if data is already in our store.
 * @return {Promise} a list of tarriffVO objects grouped by their type
 */
export const fetchTariffs = (options, isForce) => async (dispatch, getState) => {
  const request = new TariffsRequest(getMsisdn(getState()), options);
  await dispatch(sendIfNecessary(request, isForce));
  const { user, entities } = getState();
  const tariffCategories = ['bookedTariffs', 'changeableTariffs', 'bookableTariffs', 'renewableTariffs'];
  return Object.assign({}, ...tariffCategories.map(tariffCategory => ({
    [tariffCategory]: user[tariffCategory].map(iid => entities.tariffVO[iid]),
  })));
};

/**
 * Fetches the an Entity based on the given internal id from store or from server. The entity
 * is also stored in the store (`REQUEST_RECEIVED_RESPONSE` is processed in entites reducer).
 *
 * @param {number} internalId of the entity.
 * @param {boolean} [isForce=false] - fetch from server even if data is already in our store.
 * @return {Promise} Map from internal-ids to entity-ids
 */
export const fetchEntityByInternalId = (internalId, isForce) => async (dispatch, getState) => {
  const { internalIdMap } = getState().site;
  return dispatch(fetchEntityById(internalIdMap[internalId], isForce));
};

export const fetchSearchResults = searchOptions => async dispatch => {
  const response = await dispatch(send(new SearchServiceRequest(searchOptions)));
  return response.body;
};

export const fetchSearchSuggestions = searchOptions => async dispatch => {
  const response = await dispatch(send(new SearchSuggestionRequest(searchOptions)));
  return response.body;
};

/**
 * Fetches the the document ID for the EECC specific usercontract data for the ordering process
 * It is the first call of three necessary calls for the orderingprocess, see
 *
 * https://confluence.db-n.com/pages/viewpage.action?pageId=48344534
 *
 * @param requestData
 * @returns {string} Process_id for the order specific EECC Document.
 */
export const fetchEECCOrderingInitialData = requestData => async (dispatch, getState) => {
  await dispatch(send(new EECCOrderingInitiateRequest(requestData)));
  return getState().user.EECC.ordering.processId;
};

/**
 * Fetches the document ID for the EECC specific mnp data
 * for the mnp ordering process
 *
 * @param requestData: {
 *   mobile_number_portability: {
 *     gender: string,
 *     first_name: string,
 *     last_name: string,
 *     birth_date: string,
 *     porting_msisdn: string,
 *     network_key: string,
 *     provider_key: string,
 *     porting_date_type: string,
 *     porting_consent: Boolean,
 *   },
 *   product: {
 *     sim_type: string,
 *   },
 * }
 * @returns {string} Process_id for the order specific EECC Document.
 */
export const fetchEECCBMnpPortingInitialData = requestData => async (dispatch, getState) => {
  await dispatch(send(new EECCBMnpPortingInitiateRequest(getMsisdn(getState()), requestData)));
  return getState().user.EECC.bmnp.processId;
};

/**
 * Fetches the document ID for the EECC specific usercontract data
 * for the promotion ordering process
 *
 * @param requestData
 * @returns {string} Process_id for the order specific EECC Document.
 */
export const fetchEECCPromotionInitialData = requestData => async (dispatch, getState) => {
  await dispatch(send(new EECCPromotionsOrderInitiateRequest(requestData, getMsisdn(getState()))));
  return getState().user.EECC.promotion.processId;
};

/**
 * Fetches the the document ID for the EECC specific usercontract data for the activation process
 * It is the first call of three necessary calls for the activationprocess, see
 *
 * https://confluence.db-n.com/pages/viewpage.action?pageId=48344534
 *
 * @param requestData
 * @returns {string} Process_id for the activation specific EECC Document.
 */
export const fetchEECCActivationInitialData = requestData => async (dispatch, getState) => {
  await dispatch(send(new EECCActivationInitiateRequest(requestData)));
  return getState().user.EECC.activation.processId;
};

/**
 *
 */
export const fetchEECCTariffChangeInitialData = (msisdn, requestData) =>
  async (dispatch, getState) => {
    await dispatch(send(new EECCTariffChangeInitiateRequest(msisdn, requestData)));
    return getState().user.EECC.tariffChange.processId;
  };

/**
 * @return {Promise} Fetches the friend referral link within
 * a referral token and referral url with token
 */
export const fetchFriendReferralLink = (options) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(
    new FriendReferralLinkRequest(getMsisdn(getState()), options)),
  );
  return getState().friendReferral.referralLink;
};

/**
 * @return {Promise} Fetches the friend referral status within a list
 * of used referral booking, used referrals and referral eligibility
 */
export const fetchFriendReferralStatus = (options) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(
    new FriendReferralStatusRequest(getMsisdn(getState()), options)),
  );
  return getState().friendReferral.referralStatus;
};

/**
 * @return {Promise} Fetches the friend referral history of
 * all past referred friends
 */
export const fetchFriendReferralHistory = (options) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(
    new FriendReferralHistoryRequest(getMsisdn(getState()), options)),
  );
  return getState().friendReferral.referralHistory;
};

/**
 * @return {Promise} Fetches the grouped article information devices with esim and sim support
 */
export const fetchArticleInformationGroups = () => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(new ArticleInformationGroupsRequest()));
  return getState().user.articleInformationGroups;
};

/**
 * Fetches required information for an eSIM on device activation
 * @param {Object} options
 * @returns {
 *   smdpPlus: string,
 *   activationCode: string,
 *   confirmationCode: string,
 *   completeActivationCode: string
 * } Returns an eSIM information object
 */
export const fetchSimCardESimInformation = (options) => async (dispatch, getState) => {
  await dispatch(send(
    new SimCardESimInformationRequest(getMsisdn(getState()), options)),
  );
  return getState().user.eSim.information;
};

/**
 * Fetches required information for an eSIM on device activation in the form of a scanable qr code
 * @param {number} [size] - (integer) size of the QR code in pixel
 * @param {Object} options
 * @returns {
 *   smdpPlus: string,
 *   activationCode: string,
 *   confirmationCode: string,
 *   quickResponseCode: string,
 *   completeActivationCode: string
 * } Returns an eSIM qr object
 */
export const fetchSimCardESimQR = ({ size }, options) => async (dispatch, getState) => {
  await dispatch(send(
    new SimCardESimQRRequest(getMsisdn(getState()), size, options)),
  );
  return getState().user.eSim.qr;
};

/**
 * Fetches the status of an eSIM push activation
 * @param options
 * @param {boolean} [updateContractData] - whether the simCard eSimStatus should be synced
 * @returns {
 *   eid: string,
 *   puk: string,
 *   esimStatus: string,
 *   esimProvisionStatus: string,
 *   possibleActions: Array.<string>
 * } Returns an eSIM status object
 */
export const fetchSimCardESimStatus =
  (options, updateContractData = false) => async (dispatch, getState) => {
    const response = await dispatch(send(
      new SimCardESimStatusRequest(getMsisdn(getState()), options)),
    );
    if (response && updateContractData && getState().user.eSim.status) {
      dispatch(updateContractDataESimStatus(response.body.data));
    }
    return getState().user.eSim.status;
  };

/**
 * Fetches the status of an eSIM if it is ready to pair
 * @param options
 * @returns {
 *   coolDown: Number,
 *   readyToPair: boolean
 * } Returns an eSIM readyToPair object
 */
export const fetchESimReadyToPair = (options) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(
    new ESimNotificationReadyToPairRequest(getMsisdn(getState()), options)),
  );
  return getState().user.eSim.readyToPair;
};

/**
 * Fetches a Captcha from the backend
{
    "data": {
        "token": "string (backend captcha id)",
        "image": {
            "data": "string",
            "mime": "string (mimetype)"
        },
        "challenge": "string (challenge type)"
    },
    "meta": {
        "notifications": []
    }
}
 */
export const fetchCaptchaData = () => async dispatch => {
  const response = await dispatch((send(new CaptchaInitiateRequest())));
  if (response.status === 200) {
    dispatch(registerCaptcha(response.body.data));
  }
};

export const fetchTelljaSession = (advId) => async (dispatch) => {
  const response = await dispatch((send(new TelljaSessionRequest(advId))));
  if (response.status === 200) {
    return response.body.data;
  }
};
export const registerTelljaCashback = (advId, telljaData) => async (dispatch) => {
  const response = await dispatch((send(new TelljaRegistrationRequest(advId, telljaData))));
  if (response.status === 200) {
    return response.body.data;
  }
};
